.Primitive {
    background-color: dimgray;
    box-shadow: inset 0 0 110px #000000;
}

.Ramshackle {
    background-color: green;
    box-shadow: inset 0 0 110px #000000;
}

.Apprentice {
    background-color: blue;
    box-shadow: inset 0 0 110px #000000;
}

.Journeyman {
    background-color: purple;
    box-shadow: inset 0 0 110px #000000;
}

.Mastercraft {
    background-color: yellow;
    box-shadow: inset 0 0 110px #000000;
}

.Ascendant {
    background-color: lightseagreen;
    box-shadow: inset 0 0 110px #000000;
}

.stat-line {
    display: flex;
    justify-content: space-between;
}

.price {
    display: inline-block !important;
    width: 100%;
    background-color: #d1d1d1;
}

.coin { 
    font-size: x-large !important;
    position: relative;
    top: 5px;
    margin-left: 5px;
}

.Copper {
    color: #9E6624
}

.Silver {
    color: #A1A1A1
}

.Gold {
    color:#E8D219
}

.Platinum {
    color: #E8E8E8
}

.Male {
    background-color: royalblue;    
    box-shadow: inset 0 0 110px #000000;
}

.Female {
    background-color: hotpink;
    box-shadow: inset 0 0 110px #000000;
}